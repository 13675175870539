<template>
  <div>
    <title>SIM INVENTORY ~ UPDATE PRODUCT PROCESS DETAILS BOM MATERIAL</title>
    <section class="content-header">
      <h1>
        Update Product Process Details BOM Material
        <br />
        <h4>
          Please enter the transaction data for the product process details BOM
          material
        </h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Product Process Details BOM Material</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">Data Proses Produksi Detail Material</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-9">
                    <label for="exampleInputEmail1">Item Code</label>
                    <input
                      type="text"
                      v-model="kodebarang"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Item Code"
                    />
                    <input
                      type="hidden"
                      v-model="idxprodprosbommat"
                      autocomplete="off"
                      class="form-control"
                    />
                    <input
                      type="hidden"
                      v-model="idxproddprosbom"
                      autocomplete="off"
                      class="form-control"
                    />
                  </div>
                  <div class="col-xs-3">
                    <label for="exampleInputEmail1">Action</label>
                    <br />
                    <button
                      type="button"
                      @click="getbarang()"
                      class="btn btn-info"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <br />
                <div class="form-group">
                  <label for="exampleInputEmail1">Item Name </label>
                  <input
                    type="text"
                    readonly
                    v-model="namabarang"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="notesprosesprodm"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Qty</label>
                  <input
                    type="text"
                    v-model="qtyprosesdmi"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Qty"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Unit</label>
                  <input
                    type="text"
                    v-model="satuan"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Unit"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <button @click="kembali()" class="btn btn-success">
                  <i class="fa fa-angle-double-left"></i> Back
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addmasterdata",
  data() {
    return {
      loading: false,
      kodebarang: "",
      namabarang: "",
      notesprosesprodm: "",
      qtyprosesdmi: "",
      satuan: "",
      idxprodprosbommat: "",
      idxproddprosbom: ""
    };
  },
  created() {
    this.fetchdata();
    this.idxprodprosbommat = sessionStorage.getItem("idprodprosbommat");
  },
  methods: {
    kembali() {
      this.$router.push({
        name: "listprosesprodukbommaterialdetail",
        params: { id: this.idxproddprosbom }
      });
    },
    async fetchdata() {
      this.loading = true;
      const urlAPIget =
        this.$apiurl +
        "produk_d_bom_proses_material/getproduk_d_bom_proses_materialbyid?id=" +
        this.$route.params.id;

      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.idxprodprosbommat = resp.data.data.id;
          this.idxproddprosbom = resp.data.data.id_produk_d_bom_proses;
          this.kodebarang = resp.data.data.kode_barang;
          this.satuan = resp.data.data.unit;
          this.notesprosesprodm = resp.data.data.notes;
          this.qtyprosesdmi = resp.data.data.qty;
          this.satuan = resp.data.data.unit;
          this.loading = false;

          const urlapigetb =
            this.$apiurl +
            "barang/getbarangbykode_barang?kode_barang=" +
            this.kodebarang;

          axios
            .get(urlapigetb, { headers })
            .then((respn) => {
              if (respn.data.pesan == "Data not found") {
                this.namabarang = "";
              } else {
                this.kodebarang = resp.data.data.kode_barang;
                this.namabarang = resp.data.data.nama_barang;
              }
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
            });
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    async getbarang() {
      this.loading = true;
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang/getbarangbykode_barang?kode_barang=" + this.isidata.kodebarangbomd;
      const urlAPIget =
        this.$apiurl +
        "barang/getbarangbykode_barang?kode_barang=" +
        this.isidata.kodebarang;
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, Item Code not found",
              showConfirmButton: false
            });
          } else {
            this.isidata.kodebarang = resp.data.data.kode_barang;
            this.isidata.namabarang = resp.data.data.nama_barang;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    async updateData() {
      this.loading = true;
      var kdbrg = this.kodebarang;
      var nmbrg = this.namabarang;
      var catat = this.notesprosesprodm;
      var qty = this.qtyprosesdmi;
      var sat = this.satuan;
      if (kdbrg == "" || nmbrg == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Item Code can't be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (catat == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can't be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (qty == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty can't be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (sat == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Unit can't be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        var kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          id_produk_d_bom_proses: this.idxprodprosbommat,
          kode_barang: this.kodebarang,
          notes: this.notesprosesprodm,
          qty: this.qtyprosesdmi,
          kode_user: kodeuser,
          unit: this.satuan
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const urlAPIUpdateData =
          this.$apiurl +
          "produk_d_bom_proses_material/updateproduk_d_bom_proses_material/" +
          this.idxprodprosbommat;
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .put(urlAPIUpdateData, paramdata, { headers: headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.$router.push({
                name: "listprosesprodukbommaterialdetail",
                params: { id: this.idxprodprosbommat }
              });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
